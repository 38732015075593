import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateItem, removeItem } from './redux/rootActions';
import MicroModal from 'micromodal';

class Cart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: '',
      productId: '',
      sku: '',
      name: '',
      quantity: '0',
      totalPerCase: '',
      inventory: [],
      modalFormVisible: true,
      feedbackVisible: false
    };
  }

  componentDidMount() {
    MicroModal.init();
  }

  changeHandler = event => {
    this.setState({[event.target.name]: event.target.value});
  }

  editHandler = (id, productId, sku, name, quantity, totalPerCase) => {
    this.setState({
      id: id,
      productId: productId,
      sku: sku,
      name: name,
      quantity: quantity,
      totalPerCase: totalPerCase,
      modalFormVisible: true,
      feedbackVisible: false
    }, () => {
      MicroModal.show('modal-1', {
        awaitCloseAnimation: true
      });
    });
  }

  removeHandler = (id) => {
    this.props.removeItem(id);
  }

  updateHandler = (evt) => {
    evt.preventDefault();
    this.props.updateItem(this.state.id, this.state.quantity);

    this.setState({
      modalFormVisible: false,
      feedbackVisible: true
    });
  }

  render() {
    let addedItems = this.props.items.length ?
      (
        this.props.items.map(item => {
          return(
            <tr key = {item.id}>
                <td>{item.productId}</td>
                <td>{item.sku}</td>
                <td>{item.name}</td>
                <td>{item.quantity}</td>
                <td><button className="default button" onClick={()=>{this.editHandler(item.id, item.productId, item.sku, item.name, item.quantity, item.totalPerCase)}}>Edit</button></td>
                <td><button className="alert button" onClick={()=>{if(window.confirm('Are you sure you want to remove this product?')) this.removeHandler(item.id)}}>Remove</button></td>
            </tr>
          )
        })
      ):
      (
        <tr>
          <td colspan="100%"><p>Your cart is empty. <Link className="cart__link" to="/order">Click here</Link> to update.</p></td>
        </tr>
      )

    return (
      <section className="cart">
        <div className="grid-container">
          <div className="grid-x grid-padding-x">
            <div className="small-12 cell center">
              <h1>Cart</h1>
              <p>Review your selection below.</p>
              <hr/>
            </div>
          </div>
        </div>
        <div className="grid-container">
          <div className="grid-x grid-padding-x">
            <div className="large-6 medium-6 small-12 cell">
              <Link className="default expanded large button" to="/order">Add Additional Products</Link>
            </div>
            <div className="large-6 medium-6 small-12 cell">
              <Link className="success expanded large button" to="/checkout" disabled={this.props.items.length === 0}>Checkout</Link>
            </div>
          </div>
        </div>
        <div className="grid-container">
          <div className="grid-x grid-padding-x">
            <div className="large-12 cell">
              <table className="cart__table">
                <thead>
                  <tr>
                    <th>Product Id</th>
                    <th>SKU</th>
                    <th>Product Name</th>
                    <th>Number of Cases</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className="cart__table-body">
                  {addedItems}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="modal micromodal-slide" id="modal-1" aria-hidden="true">
          <div className="modal__overlay" tabIndex="-1" data-micromodal-close>
            <div className="modal__container" role="dialog" aria-modal="true" aria-labelledby="modal-1-title">
              <header className="modal__header">
                <button className="modal__close" aria-label="Close modal" data-micromodal-close></button>
              </header>
              <main className="modal__content" id="modal-1-content">
                <img className="modal__logo" src="./assets/img/logo.svg" alt="UNTUCKit" />
                <form className="modal__form" onSubmit={this.updateHandler} autoComplete='off' style={{display: this.state.modalFormVisible === true  ? 'block' : 'none'}}>
                  <table className="modal__table stack">
                    <thead>
                      <tr>
                        <th>Product Id</th>
                        <th>SKU</th>
                        <th>Product Name</th>
                        <th>Number of Cases ({this.state.totalPerCase} per case)</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody className="modal__table-body">
                      <tr key={this.state.id}>
                        <td>{this.state.productId}</td>
                        <td>{this.state.sku}</td>
                        <td>{this.state.name}</td>
                        <td><input type="text" name="quantity" value={this.state.quantity} onChange={this.changeHandler} /></td>
                        <td>
                          <button type="submit" class="default button">Update</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </form>
                <div className="modal__feedback" style={{display: this.state.feedbackVisible === true  ? 'block' : 'none'}}>
                  <p class="modal__copy">Product updated.</p>
                </div>
              </main>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return{
    items: state.addedItems
  }
}

const mapDispatchToProps = (dispatch) => {
  return{
    updateItem: (id, quantity) => { dispatch(updateItem(id, quantity)) },
    removeItem: (id) => { dispatch(removeItem(id)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Cart)
