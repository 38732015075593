import React, { useEffect } from 'react';
import { HashRouter, Routes, Route } from 'react-router-dom';
import { setUser, dataLoaded } from './redux/rootActions';
import { useAuth0 } from '@auth0/auth0-react';
import './scss/App.scss';
import Navigation from './Navigation';
import Home from './Home';
import Inventory from './Inventory';
import Order from './Order';
import Cart from './Cart';
import Checkout from './Checkout';
import OrderHistory from './OrderHistory';
import Footer from './Footer';

function App() {
  const { isLoading, isAuthenticated, loginWithRedirect, user } = useAuth0();

  if (!isLoading && !isAuthenticated) loginWithRedirect();

  return (
    <HashRouter>
      <div className="app">
        <Navigation />
        <main className="main">
          <Routes>
            <Route exact path="/" element={<Home />}></Route>
            <Route path="/inventory" element={<Inventory />}></Route>
            <Route path="/order" element={<Order />}></Route>
            <Route path="/cart" element={<Cart />}></Route>
            <Route path="/checkout" element={<Checkout />}></Route>
            <Route path="/order-history" element={<OrderHistory />}></Route>
          </Routes>
        </main>
        <Footer />
      </div>
    </HashRouter>
  );
}


export default App;
