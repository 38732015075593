import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { addToCart } from './redux/rootActions';
import axios from 'axios';
import MicroModal from 'micromodal';

class Order extends Component {
  constructor(props) {
    super(props);

    this.state = {
      productIds: [],
      productId: '',
      selectedProductId: '',
      skus: [],
      sku: '',
      selectedSku: '',
      names: [],
      name: '',
      selectedName: '',
      selectedTotal: '',
      inventory: [],
      selectedInventory: [],
      quantity: '0',
      feedbackVisible: false,
      modalFormVisible: true
    };
  }

  initProducts() {
    axios.get('./php/get-all-inventory.php')
    .then((response) => {
      this.setState({
        inventory: response.data,
        selectedInventory: response.data
      }, () => {
        const productIds = [],
              skus = [],
              names = [];

        response.data.map((data) => {
          if(data.productId !== null && data.productId !== '') {
            productIds.push(data.productId);
          }

          if(data.sku !== null && data.sku !== '') {
            skus.push(data.sku);
          }

          if(data.name !== null && data.name !== '') {
            names.push(data.name);
          }
        });

        this.setState({
          productIds: productIds.sort(),
          skus: skus.sort(),
          names: names.sort()
        });
      });
    });
  }

  getProductIds() {
    return this.state.productIds.map((productId) => {
      return <option key={productId} value={productId}>{productId}</option>;
    });
  }

  getSKUs() {
    return this.state.skus.map((sku) => {
      return <option key={sku} value={sku}>{sku}</option>;
    });
  }

  getProductNames() {
    return this.state.names.map((name) => {
      return <option key={name} value={name}>{name}</option>;
    });
  }

  handleProductIdChange = event => {
    this.setState({
      productId: event.target.value,
      sku: '',
      name: ''
    }, () => {
      const selectedInventory = [];

      this.state.inventory.map((data) => {
        if(data.productId.toString() === this.state.productId.toString()) {
          selectedInventory.push(data);
        }
      });

      this.setState({selectedInventory: selectedInventory});
    });
  }

  handleSkuChange = event => {
    this.setState({
      sku: event.target.value,
      productId: '',
      name: ''
    }, () => {
      const selectedInventory = [];

      this.state.inventory.map((data) => {
        if(data.sku.toString() === this.state.sku.toString()) {
          selectedInventory.push(data);
        }
      });

      this.setState({selectedInventory: selectedInventory});
    });
  }

  handleProductNameChange = event => {
    this.setState({
      name: event.target.value,
      productId: '',
      sku: ''
    }, () => {
      const selectedInventory = [];

      this.state.inventory.map((data) => {
        if(data.name.toString() === this.state.name.toString()) {
          selectedInventory.push(data);
        }
      });

      this.setState({selectedInventory: selectedInventory});
    });
  }

  handleReset = event => {
    this.setState({
      productIds: [],
      productId: '',
      selectedProductId: '',
      skus: [],
      sku: '',
      selectedSku: '',
      names: [],
      name: '',
      selectedName: '',
      inventory: [],
      selectedInventory: []
    }, () => {
      this.initProducts();
    });
  }

  changeHandler = event => {
    this.setState({[event.target.name]: event.target.value});
  }

  componentDidMount() {
    MicroModal.init();
    this.initProducts();
  }

  showModal = (evt) => {
    this.setState({
      selectedProductId: evt.target.getAttribute('data-productId'),
      selectedSku: evt.target.getAttribute('data-sku'),
      selectedName: evt.target.getAttribute('data-name'),
      selectedTotal: evt.target.getAttribute('data-total'),
      quantity: '0',
      modalFormVisible: true,
      feedbackVisible: false
    }, () => {
      MicroModal.show('modal-1', {
        awaitCloseAnimation: true
      });
    });
  }

  addToCartHandler = (evt) => {
    evt.preventDefault();

    this.setState({
      modalFormVisible: false,
      feedbackVisible: true
    }, () => {
      this.props.addToCart(this.state.selectedProductId, this.state.selectedSku, this.state.selectedName, this.state.quantity, this.state.selectedTotal);
    });
  }

  render() {
    let addedProducts = this.state.selectedInventory.length ?
      (
        this.state.selectedInventory.map((item, key) => {
          if(item.productId !== '' && item.sku !== '' && item.name !== '') {
            if(item.inStock === 'Yes') {
              return (
                 <tr key = {key}>
                  <td>{item.productId}</td>
                  <td>{item.sku}</td>
                  <td>{item.name}</td>
                  <td><button type="button" className="default button" data-id={key} data-productId={item.productId} data-sku={item.sku} data-name={item.name} data-total={item.totalPerCase} onClick={(evt) => this.showModal(evt)}>Select</button></td>
                 </tr>
               )
            } else if(item.inStock === 'No') {
              return (
                 <tr key = {key}>
                  <td>{item.productId}</td>
                  <td>{item.sku}</td>
                  <td>{item.name}</td>
                  <td>Out of Stock</td>
                 </tr>
               )
            }
          }
        })
      ):
      (
        <tr>
          <td colspan="100%" className="center"><p>The Inventory is currently empty.</p></td>
        </tr>
      )

    return (
      <section className="request">
        <div className="grid-container">
          <div className="grid-x grid-padding-x">
            <div className="small-12 cell center">
              <h1>New Order</h1>
              <p>Make your selection below.</p>
              <hr/>
            </div>
          </div>
        </div>
        <form id='orderForm' autoComplete='off'>
          <div className="grid-container">
            <div className="grid-x grid-padding-x">
              <div className="large-4 medium-4 small-12 cell">
                <label>Product Id
                  <select name="productId" value={this.state.productId} onChange={this.handleProductIdChange} disabled={this.state.productIds.length < 1 ? true : false}>
                    <option value="" selected="selected">
                      -- Select Product Id --
                    </option>
                    {this.getProductIds()}
                  </select>
                </label>
              </div>

              <div className="large-4 medium-4 small-12 cell">
                <label>SKU
                  <select name="sku" value={this.state.sku} onChange={this.handleSkuChange} disabled={this.state.skus.length < 1 ? true : false}>
                    <option value="" selected="selected">
                      -- Select SKU --
                    </option>
                    {this.getSKUs()}
                  </select>
                </label>
              </div>

              <div className="large-4 medium-4 small-12 cell">
                <label>Product Name
                  <select name="name" value={this.state.name} onChange={this.handleProductNameChange} disabled={this.state.names.length < 1 ? true : false}>
                    <option value="" selected="selected">
                      -- Select Product Name --
                    </option>
                    {this.getProductNames()}
                  </select>
                </label>
              </div>
            </div>
          </div>
        </form>
        <div className="grid-container">
          <div className="grid-x grid-padding-x">
            <div className="large-6 medium-6 small-12 cell">
              <button type="button" className="default expanded large button" onClick={this.handleReset}>Reset Filters</button>
            </div>
            <div className="large-6 medium-6 small-12 cell">
              <Link className="success expanded large button" to="/checkout" disabled={this.props.items.length === 0}>Checkout</Link>
            </div>
          </div>
        </div>
        <div className="grid-container">
          <div className="grid-x grid-padding-x">
            <div className="small-12 cell">
              <table className="inventory__table">
                <thead>
                  <tr>
                    <th>Product Id</th>
                    <th>SKU</th>
                    <th>Product Name</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className="inventory__table-body">
                  {addedProducts}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="modal micromodal-slide" id="modal-1" aria-hidden="true">
          <div className="modal__overlay" tabIndex="-1" data-micromodal-close>
            <div className="modal__container" role="dialog" aria-modal="true" aria-labelledby="modal-1-title">
              <header className="modal__header">
                <button className="modal__close" aria-label="Close modal" data-micromodal-close></button>
              </header>
              <main className="modal__content" id="modal-1-content">
                <img className="modal__logo" src="./assets/img/logo.svg" alt="UNTUCKit" />
                <form className="modal__form" onSubmit={this.addToCartHandler} autoComplete='off' style={{display: this.state.modalFormVisible === true  ? 'block' : 'none'}}>
                  <table className="modal__table stack">
                    <thead>
                      <tr>
                        <th>Product Id</th>
                        <th>SKU</th>
                        <th>Product Name</th>
                        <th>Number of Cases ({this.state.selectedTotal} per case)</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody className="modal__table-body">
                      <tr key={this.state.id}>
                        <td>{this.state.selectedProductId}</td>
                        <td>{this.state.selectedSku}</td>
                        <td>{this.state.selectedName}</td>
                        <td><input type="text" name="quantity" value={this.state.quantity} onChange={this.changeHandler} /></td>
                        <td>
                          <button type="submit" class="success button">Add</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </form>
                <div className="modal__feedback" style={{display: this.state.feedbackVisible === true  ? 'block' : 'none'}}>
                  <p class="modal__copy">Product added to <Link className="request__link" to="/cart">cart</Link>.</p>
                </div>
              </main>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return{
    items: state.addedItems
  }
}

const mapDispatchToProps = (dispatch) => {
  return{
    addToCart: (productId, sku, name, quantity, totalPerCase) => {
      dispatch(addToCart(productId, sku, name, quantity, totalPerCase))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Order)
