import { DATA_LOADED, SET_USER, ADD_TO_CART, UPDATE_ITEM, REMOVE_ITEM, REMOVE_ALL } from './types'

const initState = {
    isLoaded: false,
    user: null,
    id: 0,
    addedItems: []
}

const rootReducer = (state = initState, action) => {
  switch (action.type) {
    case DATA_LOADED:
      return {
        ...state,
        isLoaded: action.isLoaded
      }
      break;
    case SET_USER:
      return {
        ...state,
        user: action.user
      }
      break;
    case ADD_TO_CART:
      state.id = state.id + 1;

      return {
        ...state,
        addedItems: [...state.addedItems, { id:state.id, productId: action.productId, sku: action.sku, name: action.name, quantity: action.quantity, totalPerCase: action.totalPerCase }]
      }
      break;
    case UPDATE_ITEM:
      const updatedItems = state.addedItems.map((item, index) => {
        const id1 = parseInt(item.id),
              id2 = parseInt(action.id);

        if(id1 === id2) {
          return {
            ...item,
            quantity: action.quantity
          }
        }
        return item;
      });

      return {
        ...state,
        addedItems: updatedItems
      }
      break;
    case REMOVE_ITEM:
      const removedItems = state.addedItems.filter(item => action.id !== item.id)

      return {
        ...state,
        addedItems: removedItems
      }
      break;
    case REMOVE_ALL:
      return {
        ...state,
        addedItems: []
      }
      break;
    default: {
      return {
        ...state
      }
    }
  }
}

export default rootReducer;
