import React, { Component, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { connect } from 'react-redux';
import { removeAll } from './redux/rootActions';
import axios from 'axios';
import MicroModal from 'micromodal';

const AuthUser = ({ func }) => {
  const { isLoading, isAuthenticated, user } = useAuth0();

  useEffect(() => {
    if (!isLoading && isAuthenticated) func(user);
  }, [isLoading, isAuthenticated]);
}

class Checkout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      stores: [],
      storeLocations: {},
      storeLocation: '',
      storeAddress1: '',
      storeAddress2: '',
      storeCity: '',
      storeState: '',
      storeZipcode: '',
      products: '',
      status: '',
      feedback: '',
      user: null,
      admin: null,
      token: ''
    };
  }

  changeHandler = event => {
    this.setState({[event.target.name]: event.target.value});
  }

  initMap = (_address1, _address2, _city, _state, _zipcode) => {
    const map = new window.google.maps.Map(document.getElementById("map"), {
      zoom: 15,
      center: { lat: 51.5085300, lng: -0.1257400 },
      disableDefaultUI: true,
    });

    const geocoder = new window.google.maps.Geocoder(),
          address = `${_address1}, ${_city}, ${_state} ${_zipcode}`;

    geocoder.geocode( {'address': address}, function(results, status) {
      if (status === 'OK') {
        map.setCenter(results[0].geometry.location);

        const address2 = (_address2 !== '') ? `${_address2}<br/>` : '';

        const contentString = `<b>SHIPPING ADDRESS</b><br/>${_address1}<br/>${address2}${_city}<br/>${_state} ${_zipcode}`;

        const infowindow = new window.google.maps.InfoWindow({
          content: contentString,
        });

        const marker = new window.google.maps.Marker({
            map: map,
            position: results[0].geometry.location
        });

        marker.addListener('click', () => {
          infowindow.open({
            anchor: marker,
            map,
            shouldFocus: false,
          });
        });
      } else {
        console.log('Geocode was not successful for the following reason: ' + status);
      }
    });
  }

  submitHandler = event => {
    event.preventDefault();

    this.setState({status: 'Submitting information. Please wait.'});

    window.grecaptcha.ready(() => {
      window.grecaptcha.execute('6LeV0lMmAAAAABhK4YPq88BvDMQa9EfqcpCM659y', {action: 'formsubmit'}).then((token) => {
        let fd = new FormData();
        fd.append('g-recaptcha-response', token);
        fd.append('firstName', this.state.firstName);
        fd.append('lastName', this.state.lastName);
        fd.append('email', this.state.email);
        fd.append('phone', this.state.phone);
        fd.append('storeLocation', this.state.storeLocation);
        fd.append('storeAddress1', this.state.storeAddress1);
        fd.append('storeAddress2', this.state.storeAddress2);
        fd.append('storeCity', this.state.storeCity);
        fd.append('storeState', this.state.storeState);
        fd.append('storeZipcode', this.state.storeZipcode);

        let products = '';

        if(this.props.items.length){
          this.props.items.map(item => {
            products += `${item.productId}, ${item.sku}, ${item.name}, ${item.quantity};`;
          });
        }

        fd.append('products', products);

        axios({
          method: 'post',
          url: './php/submit-form.php',
          data: fd
        })
        .then(response => {
          this.initMap(this.state.storeAddress1, this.state.storeAddress2, this.state.storeCity, this.state.storeState, this.state.storeZipcode);

          this.setState({
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            storeLocation: '',
            storeAddress1: '',
            storeAddress2: '',
            storeCity: '',
            storeState: '',
            storeZipcode: '',
            status: '',
            feedback: `Thank you for your order. You will receive a confirmation email once your order has been shipped.`
          }, () => {
            MicroModal.show('checkoutModal', {
              awaitCloseAnimation: true
            });

            this.props.removeAll();
          });
        })
        .catch(err => {
          this.setState({status: 'Error submitting data. Please try again.'});
        });
      });
    });
  }

  getStoreLocations() {
    return this.state.stores.map((location) => {
      return <option key={location} value={location}>{location}</option>;
    });
  }

  getStoreLocation = (user) => {
    this.setState({
      user: user,
      admin: user['https://untuckitsupplies.com/roles'].includes('Administrator')
    }, () => {
      if(!this.state.admin) {
        axios.get('./data/stores.json')
        .then((response) => {
          this.setState({
            storeLocations: response.data
          }, () => {
            let storeLocation = '';

            for (let key in this.state.storeLocations) {
              if (this.state.storeLocations.hasOwnProperty(key)) {
                if(this.state.storeLocations[key].storeEmail === this.state.user.email) {
                  storeLocation = key;
                }
              }
            }

            if(storeLocation !== '') {
              this.setState({
                storeLocation: storeLocation,
                firstName: 'Store',
                lastName: 'Manager',
                email: this.state.storeLocations[storeLocation].storeEmail,
                phone: this.state.storeLocations[storeLocation].storePhone,
                storeAddress1: this.state.storeLocations[storeLocation].storeAddress1,
                storeAddress2: this.state.storeLocations[storeLocation].storeAddress2,
                storeCity: this.state.storeLocations[storeLocation].storeCity,
                storeState: this.state.storeLocations[storeLocation].storeState,
                storeZipcode: this.state.storeLocations[storeLocation].storeZipcode
              });
            }
          });
        });
      }
    });
  }

  stateChangeHandler = event => {
    this.setState({
      storeState: event.target.value,
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      storeLocation: '',
      storeAddress1: '',
      storeAddress2: '',
      storeCity: '',
      storeZipcode: ''
    }, () => {
      axios.get('./data/stores.json')
      .then((response) => {
        this.setState({
          storeLocations: response.data
        }, () => {
          let stores = [];

          for (let key in this.state.storeLocations) {
            if (this.state.storeLocations.hasOwnProperty(key)) {
              if(this.state.storeLocations[key].storeState === this.state.storeState) {
                stores.push(key);
              }
            }
          }

          this.setState({
            stores: stores
          });
        });
      });
    });
  }

  storeLocationChangeHandler = event => {
    this.setState({
      storeLocation: event.target.value
    }, () => {
      if(this.state.storeLocation !== '') {
        this.setState({
          firstName: 'Store',
          lastName: 'Manager',
          email: this.state.storeLocations[this.state.storeLocation].storeEmail,
          phone: this.state.storeLocations[this.state.storeLocation].storePhone,
          storeAddress1: this.state.storeLocations[this.state.storeLocation].storeAddress1,
          storeAddress2: this.state.storeLocations[this.state.storeLocation].storeAddress2,
          storeCity: this.state.storeLocations[this.state.storeLocation].storeCity,
          storeZipcode: this.state.storeLocations[this.state.storeLocation].storeZipcode
        });
      } else {
        this.setState({
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          storeAddress1: '',
          storeAddress2: '',
          storeCity: '',
          storeZipcode: ''
        });
      }
    });
  }

  render() {
    let addedItems = this.props.items.length ?
      (
        this.props.items.map(item => {
          return(
            <tr key = {item.id}>
                <td>{item.productId}</td>
                <td>{item.sku}</td>
                <td>{item.name}</td>
                <td>{item.quantity}</td>
            </tr>
          )
        })
      ):
      (
        <tr>
          <td colSpan="100%"><p>Your cart is empty. <Link className="checkout__link" to="/order">Click here</Link> to start a new order.</p></td>
        </tr>
      )

    return (
      <section className="checkout">
        <div className="grid-container">
          <div className="grid-x grid-padding-x">
            <div className="large-12 cell center">
              <h1>Checkout</h1>
              <p>Please review your selection and complete the form below.</p>
              <AuthUser func={this.getStoreLocation} />
              <hr />
            </div>
          </div>
        </div>
        <div className="grid-container">
          <div className="grid-x grid-padding-x">
            <div className="large-12 cell">
              <table className="checkout__table">
                <thead>
                  <tr>
                    <th>Product Id</th>
                    <th>SKU</th>
                    <th>Product Name</th>
                    <th>Number of Cases</th>
                  </tr>
                </thead>
                <tbody className="checkout__table-body">
                  {addedItems}
                </tbody>
              </table>
            </div>
            <div className="large-4 large-offset-4 medium-6 medium-offset-3 small-12 small-offset-0 cell">
              <Link className="default expanded large button" to="/cart">Edit Cart</Link>
            </div>
          </div>
        </div>
        <form className="form" id="checkoutForm" onSubmit={this.submitHandler}>
          <div className="grid-container">
            <div className="grid-x grid-padding-x">
              <div className="large-6 medium-6 small-12 cell" style={{display: !this.state.admin ? 'none' : 'block'}}>
                <label>State
                  <select name="storeState" onChange={this.stateChangeHandler} value={this.state.storeState} required={!this.state.admin ? false : true}>
                    <option value="">-- Select State --</option>
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="DC">District Of Columbia</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                    <option value="AB">Alberta</option>
                    <option value="ON">Ontario</option>
                  </select>
                </label>
              </div>
              <div className="large-6 medium-6 small-12 cell" style={{display: !this.state.admin ? 'none' : 'block'}}>
                <label>Store Location
                  <select name="storeLocation" value={this.state.storeLocation} onChange={this.storeLocationChangeHandler} disabled={this.state.storeLocations.length < 1 ? true : false} required={!this.state.admin ? false : true}>
                    <option value="" selected="selected">
                      -- Select Store Location --
                    </option>
                    {this.getStoreLocations()}
                  </select>
                </label>
              </div>
              <div className="large-6 medium-6 small-12 cell">
                <label>First Name
                  <input type="text" name="firstName" onChange={this.changeHandler} value={this.state.firstName} maxLength="50" autoComplete="off" required />
                </label>
              </div>
              <div className="large-6 medium-6 small-12 cell">
                <label>Last Name
                  <input type="text" name="lastName" onChange={this.changeHandler} value={this.state.lastName} maxLength="50" autoComplete="off" required />
                </label>
              </div>
              <div className="large-6 medium-6 small-12 cell">
                <label>Email Address
                  <input type="email" name="email" onChange={this.changeHandler} value={this.state.email} maxLength="100" autoComplete="off" required />
                </label>
              </div>
              <div className="large-6 medium-6 small-12 cell">
                <label>Phone Number
                  <input type="text" name="phone" onChange={this.changeHandler} value={this.state.phone} maxLength="12" placeholder="999-999-9999" autoComplete="off" required />
                </label>
              </div>
              <div className="large-6 medium-6 small-12 cell" style={{display: !this.state.admin ? 'block' : 'none'}}>
                <label>Store Location
                  <input type="text" name="storeLocation" onChange={this.changeHandler} value={this.state.storeLocation} autoComplete="off" required={!this.state.admin ? true : false} maxLength="100" />
                </label>
              </div>
              <div className="large-6 medium-6 small-12 cell">
                <label>Address 1
                  <input type="text" name="storeAddress1" onChange={this.changeHandler} value={this.state.storeAddress1} autoComplete="off" required maxLength="100" />
                </label>
              </div>
              <div className="large-6 medium-6 small-12 cell">
                <label>Address 2
                  <input type="text" name="storeAddress2" onChange={this.changeHandler} value={this.state.storeAddress2} autoComplete="off" maxLength="100" />
                </label>
              </div>
              <div className="large-6 medium-6 small-12 cell">
                <label>City
                  <input type="text" name="storeCity" onChange={this.changeHandler} value={this.state.storeCity} autoComplete="off" required maxLength="50" />
                </label>
              </div>
              <div className="large-6 medium-6 small-12 cell" style={{display: !this.state.admin ? 'block' : 'none'}}>
                <label>State
                  <select name="storeState" onChange={this.changeHandler} value={this.state.storeState} required={!this.state.admin ? true : false}>
                    <option value="">-- Select State --</option>
                  	<option value="AL">Alabama</option>
                  	<option value="AK">Alaska</option>
                  	<option value="AZ">Arizona</option>
                  	<option value="AR">Arkansas</option>
                  	<option value="CA">California</option>
                  	<option value="CO">Colorado</option>
                  	<option value="CT">Connecticut</option>
                  	<option value="DE">Delaware</option>
                  	<option value="DC">District Of Columbia</option>
                  	<option value="FL">Florida</option>
                  	<option value="GA">Georgia</option>
                  	<option value="HI">Hawaii</option>
                  	<option value="ID">Idaho</option>
                  	<option value="IL">Illinois</option>
                  	<option value="IN">Indiana</option>
                  	<option value="IA">Iowa</option>
                  	<option value="KS">Kansas</option>
                  	<option value="KY">Kentucky</option>
                  	<option value="LA">Louisiana</option>
                  	<option value="ME">Maine</option>
                  	<option value="MD">Maryland</option>
                  	<option value="MA">Massachusetts</option>
                  	<option value="MI">Michigan</option>
                  	<option value="MN">Minnesota</option>
                  	<option value="MS">Mississippi</option>
                  	<option value="MO">Missouri</option>
                  	<option value="MT">Montana</option>
                  	<option value="NE">Nebraska</option>
                  	<option value="NV">Nevada</option>
                  	<option value="NH">New Hampshire</option>
                  	<option value="NJ">New Jersey</option>
                  	<option value="NM">New Mexico</option>
                  	<option value="NY">New York</option>
                  	<option value="NC">North Carolina</option>
                  	<option value="ND">North Dakota</option>
                  	<option value="OH">Ohio</option>
                  	<option value="OK">Oklahoma</option>
                  	<option value="OR">Oregon</option>
                  	<option value="PA">Pennsylvania</option>
                  	<option value="RI">Rhode Island</option>
                  	<option value="SC">South Carolina</option>
                  	<option value="SD">South Dakota</option>
                  	<option value="TN">Tennessee</option>
                  	<option value="TX">Texas</option>
                  	<option value="UT">Utah</option>
                  	<option value="VT">Vermont</option>
                  	<option value="VA">Virginia</option>
                  	<option value="WA">Washington</option>
                  	<option value="WV">West Virginia</option>
                  	<option value="WI">Wisconsin</option>
                  	<option value="WY">Wyoming</option>
                    <option value="AB">Alberta</option>
                    <option value="ON">Ontario</option>
                  </select>
                </label>
              </div>
              <div className="large-6 medium-6 small-12 cell">
                <label>Zip Code
                  <input type="text" name="storeZipcode" maxLength="5" onChange={this.changeHandler} value={this.state.storeZipcode} autoComplete="off" required />
                </label>
              </div>
              <div className="large-4 large-offset-4 medium-6 medium-offset-3 small-12 small-offset-0 cell">
                <input type="submit" className="success expanded large button" value="Submit" disabled={this.props.items.length === 0 ? true : false} />
              </div>
              <div className="large-12 cell center"><h4>{this.state.status}</h4></div>
            </div>
          </div>
        </form>
        <div className="modal micromodal-slide" id="checkoutModal" aria-hidden="true">
          <div className="modal__overlay" tabIndex="-1" data-micromodal-close>
            <div className="modal__container" role="dialog" aria-modal="true" aria-labelledby="view-modal-title">
              <header className="modal__header">
                <button className="modal__close" aria-label="Close modal" data-micromodal-close></button>
              </header>
              <main className="modal__content">
                <img className="modal__logo" src="./assets/img/logo.svg" alt="UNTUCKit" />
                <p className="modal__copy">{this.state.feedback}</p>
                <div id="map"></div>
              </main>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return{
    items: state.addedItems,
  }
}

const mapDispatchToProps = (dispatch) => {
  return{
    removeAll: () => { dispatch(removeAll()) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
