import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const Home = () => {
  const { isLoading, isAuthenticated, user } = useAuth0();

  return (
    isAuthenticated && (
      <section className="home">
        <div className="grid-container ">
          <div className="grid-x grid-padding-x">
            <div className="large-12 cell center">
              <h1 className="home__title">UNTUCKit Supplies Portal</h1>
              <p className="home__copy">Please select an option below.</p>
            </div>
          </div>
        </div>
        <div className="grid-container home__links">
          <div className="grid-x grid-padding-x">
            <div className="medium-6 medium-offset-3 small-12 small-offset-0 cell">
              <Link className="button large expanded" style={{display: user['https://untuckitsupplies.com/roles'].includes('Administrator') ? 'block' : 'none'}} to="/inventory">View Inventory</Link>
              <Link className="button large expanded" to="/order">New Order</Link>
              <Link className="button large expanded" to="/order-history">View Order History</Link>
            </div>
          </div>
        </div>
      </section>
    )
  );
}

export default Home;
