import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

class Inventory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      productIds: [],
      productId: '',
      skus: [],
      sku: '',
      names: [],
      name: '',
      inventory: [],
      selectedInventory: []
    };
  }

  initProducts() {
    axios.get('./php/get-all-inventory.php')
    .then((response) => {
      this.setState({
        inventory: response.data,
        selectedInventory: response.data
      }, () => {
        const productIds = [],
              skus = [],
              names = [];

        response.data.map((data) => {
          if(data.productId !== null && data.productId !== '') {
            productIds.push(data.productId);
          }

          if(data.sku !== null && data.sku !== '') {
            skus.push(data.sku);
          }

          if(data.name !== null && data.name !== '') {
            names.push(data.name);
          }
        });

        this.setState({
          productIds: productIds,
          skus: skus,
          names: names
        });
      });
    });
  }

  getProductIds() {
    return this.state.productIds.map((productId) => {
      return <option key={productId} value={productId}>{productId}</option>;
    });
  }

  getSKUs() {
    return this.state.skus.map((sku) => {
      return <option key={sku} value={sku}>{sku}</option>;
    });
  }

  getProductNames() {
    return this.state.names.map((name) => {
      return <option key={name} value={name}>{name}</option>;
    });
  }

  handleProductIdChange = event => {
    this.setState({
      productId: event.target.value,
      sku: '',
      name: ''
    }, () => {
      const selectedInventory = [];

      this.state.inventory.map((data) => {
        if(data.productId.toString() === this.state.productId.toString()) {
          selectedInventory.push(data);
        }
      });

      this.setState({selectedInventory: selectedInventory});
    });
  }

  handleSkuChange = event => {
    this.setState({
      sku: event.target.value,
      productId: '',
      name: ''
    }, () => {
      const selectedInventory = [];

      this.state.inventory.map((data) => {
        if(data.sku.toString() === this.state.sku.toString()) {
          selectedInventory.push(data);
        }
      });

      this.setState({selectedInventory: selectedInventory});
    });
  }

  handleProductNameChange = event => {
    this.setState({
      name: event.target.value,
      productId: '',
      sku: ''
    }, () => {
      const selectedInventory = [];

      this.state.inventory.map((data) => {
        if(data.name.toString() === this.state.name.toString()) {
          selectedInventory.push(data);
        }
      });

      this.setState({selectedInventory: selectedInventory});
    });
  }

  handleExport = event => {
    window.exportExcel('Inventory', 'xlsx');
  }

  handleReset = event => {
    this.setState({
      productIds: [],
      productId: '',
      skus: [],
      sku: '',
      names: [],
      name: '',
      inventory: [],
      selectedInventory: []
    }, () => {
      this.initProducts();
    });
  }

  componentDidMount() {
    this.initProducts();
  }

  render() {
    let addedProducts = this.state.selectedInventory.length ?
      (
        this.state.selectedInventory.map(function(item, key) {
          if(item.productId !== '' && item.sku !== '' && item.name !== '' && item.totalPerCase !== '') {
            return (
               <tr key = {key}>
                <td>{item.productId}</td>
                <td>{item.sku}</td>
                <td>{item.name}</td>
                <td>{item.totalPerCase}</td>
                <td>{item.inStock}</td>
               </tr>
             )
          }
        })
      ):
      (
        <tr>
          <td colspan="100%" className="center"><p>The Inventory is currently empty.</p></td>
        </tr>
      )

    return (
      <section className="inventory">
        <div className="grid-container">
          <div className="grid-x grid-padding-x">
            <div className="small-12 cell center">
              <h1>Inventory Report</h1>
              <hr/>
            </div>
          </div>
        </div>
        <form id='inventoryForm' autoComplete='off'>
          <div className="grid-container">
            <div className="grid-x grid-padding-x">
              <div className="large-4 medium-4 small-12 cell">
                <label>Product Id
                  <select name="productId" value={this.state.productId} onChange={this.handleProductIdChange} disabled={this.state.productIds.length < 1 ? true : false}>
                    <option value="" selected="selected">
                      -- Select Product Id --
                    </option>
                    {this.getProductIds()}
                  </select>
                </label>
              </div>

              <div className="large-4 medium-4 small-12 cell">
                <label>SKU
                  <select name="sku" value={this.state.sku} onChange={this.handleSkuChange} disabled={this.state.skus.length < 1 ? true : false}>
                    <option value="" selected="selected">
                      -- Select SKU --
                    </option>
                    {this.getSKUs()}
                  </select>
                </label>
              </div>

              <div className="large-4 medium-4 small-12 cell">
                <label>Product Name
                  <select name="name" value={this.state.name} onChange={this.handleProductNameChange} disabled={this.state.names.length < 1 ? true : false}>
                    <option value="" selected="selected">
                      -- Select Product Name --
                    </option>
                    {this.getProductNames()}
                  </select>
                </label>
              </div>
            </div>
          </div>
        </form>
        <div className="grid-container">
          <div className="grid-x grid-padding-x">
            <div className="large-4 medium-4 small-12 cell">
              <button type="button" className="default expanded large button" onClick={this.handleReset}>Reset Filters</button>
            </div>
            <div className="large-4 medium-4 small-12 cell">
              <button type="button" className="default expanded large button" onClick={this.handleExport}>Export Data</button>
            </div>
            <div className="large-4 medium-4 small-12 cell">
              <Link className="success expanded large button" to="/order">New Order</Link>
            </div>
          </div>
        </div>
        <div className="grid-container">
          <div className="grid-x grid-padding-x">
            <div className="small-12 cell">
              <table className="inventory__table" id="untuckitTable">
                <thead>
                  <tr>
                    <th>Product Id</th>
                    <th>SKU</th>
                    <th>Product Name</th>
                    <th>Total Per Case</th>
                    <th>In Stock</th>
                  </tr>
                </thead>
                <tbody>
                  {addedProducts}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Inventory
