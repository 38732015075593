import React, { Component, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { easepick } from '@easepick/bundle';
import Pagination from './Pagination';
import axios from 'axios';
import MicroModal from 'micromodal';

const AuthUser = ({ func }) => {
  const { isLoading, isAuthenticated, user } = useAuth0();

  useEffect(() => {
    if (!isLoading && isAuthenticated) func(user);
  }, [isLoading, isAuthenticated]);
}

class OrderHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      storeLocations: [],
      storeLocation: '',
      skus: [],
      sku: '',
      productNames: [],
      productName: '',
      orderDate: '',
      orders: [],
      selectedOrderNumber: '',
      selectedSku: '',
      selectedName: '',
      selectedQuantity: '',
      orderStatus: 'Loading...',
      user: null,
      admin: null,
      datepicker: null,
      currentPage: 1,
      recordsPerPage: 100
    };
  }

  getOrderStatus = (evt) => {
    this.setState({
      selectedOrderNumber: evt.target.getAttribute('data-orderNumber'),
      selectedSku: evt.target.getAttribute('data-sku'),
      selectedName: evt.target.getAttribute('data-productName'),
      selectedQuantity: evt.target.getAttribute('data-quantity'),
      orderStatus: 'Loading...'
    }, () => {
      let data = new FormData();
      data.append('orderNumber', this.state.selectedOrderNumber);
      data.append('sku', this.state.selectedSku);

      axios.post('./php/get-order-status.php', data)
      .then((response) => {
        this.setState({
          orderStatus: response.data['orders'][0]['orderStatus']
        });
      });

      MicroModal.show('modal-1', {
        awaitCloseAnimation: true
      });
    });
  }

  getStoreLocations() {
    return this.state.storeLocations.map((storeLocation) => {
      return <option key={storeLocation} value={storeLocation}>{storeLocation}</option>;
    });
  }

  getSKUs() {
    return this.state.skus.map((sku) => {
      return <option key={sku} value={sku}>{sku}</option>;
    });
  }

  getProductNames() {
    return this.state.productNames.map((productName) => {
      return <option key={productName} value={productName}>{productName}</option>;
    });
  }

  getOrderHistory = (user) => {
    this.setState({
      user: user,
      admin: user['https://untuckitsupplies.com/roles'].includes('Administrator')
    }, () => {
      if(this.state.admin){
        axios.get('./php/get-all-orders.php')
        .then((response) => {
          this.setState({orders: response.data}, () => {
            this.initFilters();
          });
        });
      } else {
        axios.get('./data/stores.json')
        .then((response) => {
          const storesData = response.data;

          for (let key in storesData) {
            if (storesData.hasOwnProperty(key)) {
              if(storesData[key].storeEmail === this.state.user.email) {
                this.setState({
                  storeLocation: key
                }, () => {
                  axios({
                    method: 'POST',
                    url: './php/get-order.php',
                    data: this.getFormData()
                  })
                  .then((response) => {
                    this.setState({orders: response.data}, () => {
                      this.initFilters();
                    });
                  });
                });
              }
            }
          }
        });
      }
    });
  }

  initFilters() {
    const storeLocations = [],
          skus = [],
          productNames = [];

    this.state.orders.map((data) => {
      if(data.storeLocation !== null && data.storeLocation !== '' && storeLocations.indexOf(data.storeLocation) === -1) {
        storeLocations.push(data.storeLocation);
      }

      if(data.sku !== null && data.sku !== '' && skus.indexOf(data.sku) === -1) {
        skus.push(data.sku);
      }

      if(data.productName !== null && data.productName !== '' && productNames.indexOf(data.productName) === -1) {
        productNames.push(data.productName);
      }
    });

    this.setState({
      storeLocations: storeLocations.sort(),
      skus: skus.sort(),
      productNames: productNames.sort()
    });
  }

  handleStoreLocationChange = event => {
    this.setState({
      storeLocation: event.target.value,
      skus: [],
      sku: '',
      productNames: [],
      productName: '',
      orderDate: '',
      orders: []
    }, () => {
      this.state.datepicker.clear();

      axios({
        method: 'POST',
        url: './php/get-order.php',
        data: this.getFormData()
      })
      .then((response) => {
        this.setState({orders: response.data}, () => {
          const skus = [],
                productNames = [];

          this.state.orders.map((data) => {
            if(data.sku !== null && data.sku !== '' && skus.indexOf(data.sku) === -1) {
              skus.push(data.sku);
            }

            if(data.productName !== null && data.productName !== '' && productNames.indexOf(data.productName) === -1) {
              productNames.push(data.productName);
            }
          });

          this.setState({
            skus: skus.sort(),
            productNames: productNames.sort()
          });
        });
      });
    });
  }

  handleSkuChange = event => {
    this.setState({
      sku: event.target.value,
      orders: []
    }, () => {
      axios({
        method: 'POST',
        url: './php/get-order.php',
        data: this.getFormData()
      })
      .then((response) => {
        this.setState({orders: response.data});
      });
    });
  }

  handleProductNameChange = event => {
    this.setState({
      productName: event.target.value,
      orders: []
    }, () => {
      axios({
        method: 'POST',
        url: './php/get-order.php',
        data: this.getFormData()
      })
      .then((response) => {
        this.setState({orders: response.data});
      });
    });
  }

  handleExport = event => {
    window.exportExcel('Orders', 'xlsx');
  }

  handleReset = event => {
    this.setState({
      storeLocation: '',
      sku: '',
      productName: '',
      orderDate: '',
      orders: []
    }, () => {
      this.state.datepicker.clear();

      axios.get('./php/get-all-orders.php')
      .then((response) => {
        this.setState({orders: response.data}, () => {
          this.getOrderHistory(this.state.user);
        });
      });
    });
  }

  getFormData() {
    const fd = new FormData();

    fd.append('storeLocation', this.state.storeLocation);
    fd.append('sku', this.state.sku);
    fd.append('productName', this.state.productName);
    fd.append('orderDate', this.state.orderDate);

    return fd;
  }

  componentDidMount() {
    const _this = this,
          now = new Date(),
          minDate = new Date();

    MicroModal.init();

    minDate.setFullYear(2023, 5, 2);

    const datepicker = new easepick.create({
      element: document.getElementById('datepicker'),
      css: [
        'https://cdn.jsdelivr.net/npm/@easepick/core@1.2.1/dist/index.css',
        'https://cdn.jsdelivr.net/npm/@easepick/lock-plugin@1.2.1/dist/index.css'
      ],
      format: "YYYY-MM-DD",
      readonly: true,
      plugins: ['LockPlugin'],
      LockPlugin: {
        minDate: minDate,
        maxDate: now
      },
      setup(datepicker) {
        datepicker.on('select', (e) => {
          const { date } = e.detail,
                day = date.getDate(),
                month = date.getMonth(),
                year = date.getFullYear(),
                formattedDate = `${year}-${month + 1}-${day}`;

          _this.setState({
            orderDate: formattedDate,
            orders: []
          }, () => {
            datepicker.hide();

            axios({
              method: 'POST',
              url: './php/get-order.php',
              data: _this.getFormData()
            })
            .then((response) => {
              _this.setState({orders: response.data});
            });
          });
        });

        _this.setState({datepicker: datepicker});
      }
    });
  }

  setCurrentPage(_this, page) {
    _this.setState({currentPage: page});
  }

  render() {
    const indexOfLastRecord = this.state.currentPage * this.state.recordsPerPage,
          indexOfFirstRecord = indexOfLastRecord - this.state.recordsPerPage,
          currentRecords = this.state.orders.slice(indexOfFirstRecord, indexOfLastRecord),
          nPages = Math.ceil(this.state.orders.length / this.state.recordsPerPage);

    let pastOrders = this.state.orders.length ?
      (
        currentRecords.map((item, key) => {
          return (
             <tr key = {key}>
              <td>{item.orderNumber}</td>
              <td>{item.storeLocation}</td>
              <td>{item.sku}</td>
              <td>{item.productName}</td>
              <td>{item.quantity}</td>
              <td>{item.dateSubmitted}</td>
              <td><button type="button" className="default button" data-id={key} data-orderNumber={item.orderNumber} data-sku={item.sku} data-productName={item.productName} data-quantity={item.quantity} onClick={(evt) => this.getOrderStatus(evt)}>Get Order Status</button></td>
             </tr>
           )
        })
      ):
      (
        <tr>
          <td colspan="100%" className="center"><p>The Order History is currently empty.</p></td>
        </tr>
      )

    return (
      <section className="orders">
        <div className="grid-container">
          <div className="grid-x grid-padding-x">
            <div className="small-12 cell center">
              <h1>Order History</h1>
              <AuthUser func={this.getOrderHistory} />
              <hr/>
            </div>
          </div>
        </div>
        <form id='ordersForm' autoComplete='off'>
          <div className="grid-container">
            <div className="grid-x grid-padding-x">
              <div className="large-3 medium-6 small-12 cell">
                <label>Store Location
                  <select name="storeLocation" value={this.state.storeLocation} onChange={this.handleStoreLocationChange} disabled={(this.state.storeLocations.length === 0 || !this.state.admin) ? true : false}>
                    <option value="" selected="selected">
                      -- Select Store Location --
                    </option>
                    {this.getStoreLocations()}
                  </select>
                </label>
              </div>

              <div className="large-3 medium-6 small-12 cell">
                <label>SKU
                  <select name="sku" value={this.state.sku} onChange={this.handleSkuChange} disabled={this.state.skus.length === 0 ? true : false}>
                    <option value="" selected="selected">
                      -- Select SKU --
                    </option>
                    {this.getSKUs()}
                  </select>
                </label>
              </div>

              <div className="large-3 medium-6 small-12 cell">
                <label>Product Name
                  <select name="productName" value={this.state.productName} onChange={this.handleProductNameChange} disabled={this.state.productNames.length === 0 ? true : false}>
                    <option value="" selected="selected">
                      -- Select Product Name --
                    </option>
                    {this.getProductNames()}
                  </select>
                </label>
              </div>

              <div className="large-3 medium-6 small-12 cell">
                <label>Order Date
                  <input type="text" id="datepicker" name="dateSubmitted" placeholder="YYYY-MM-DD" />
                </label>
              </div>
            </div>
          </div>
        </form>
        <div className="grid-container">
          <div className="grid-x grid-padding-x">
            <div className="large-4 medium-4 small-12 cell">
              <button type="button" className="default expanded large button" onClick={this.handleReset}>Reset Filters</button>
            </div>
            <div className="large-4 medium-4 small-12 cell">
              <button type="button" className="default expanded large button" onClick={this.handleExport}>Export Data</button>
            </div>
            <div className="large-4 medium-4 small-12 cell">
              <Link className="success expanded large button" to="/order">New Order</Link>
            </div>
          </div>
        </div>
        <div className="grid-container">
          <div className="grid-x grid-padding-x">
            <div className="small-12 cell">
              <table className="orders__table" id="untuckitTable">
                <thead>
                  <tr>
                    <th>Order Number</th>
                    <th>Store Location</th>
                    <th>SKU</th>
                    <th>Product Name</th>
                    <th>Quantity</th>
                    <th>Date Ordered</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {pastOrders}
                </tbody>
              </table>
              <Pagination
                nPages={nPages}
                currentPage={this.state.currentPage}
                setCurrentPage={this.setCurrentPage}
                _this={this}
              />
            </div>
          </div>
        </div>
        <div className="modal micromodal-slide" id="modal-1" aria-hidden="true">
          <div className="modal__overlay" tabIndex="-1" data-micromodal-close>
            <div className="modal__container" role="dialog" aria-modal="true" aria-labelledby="modal-1-title">
              <header className="modal__header">
                <button className="modal__close" aria-label="Close modal" data-micromodal-close></button>
              </header>
              <main className="modal__content" id="modal-1-content">
                <img className="modal__logo" src="./assets/img/logo.svg" alt="UNTUCKit" />
                <table className="modal__table stack">
                  <thead>
                    <tr>
                      <th>Order Number</th>
                      <th>Product Name</th>
                      <th>Quantity</th>
                      <th>Order Status</th>
                    </tr>
                  </thead>
                  <tbody className="modal__table-body">
                    <tr key={this.state.id}>
                      <td>{this.state.selectedOrderNumber}</td>
                      <td>{this.state.selectedName}</td>
                      <td>{this.state.selectedQuantity}</td>
                      <td>{this.state.orderStatus}</td>
                    </tr>
                  </tbody>
                </table>
              </main>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default OrderHistory;
