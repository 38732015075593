import React from 'react'

const Pagination = ({ nPages, currentPage, setCurrentPage, _this }) => {

    const pageNumbers = [...Array(nPages + 1).keys()].slice(1)

    const nextPage = (evt) => {
      evt.preventDefault();
      if(currentPage !== nPages) setCurrentPage(_this, currentPage + 1);
    }
    const prevPage = (evt) => {
      evt.preventDefault();
      if(currentPage !== 1) setCurrentPage(_this, currentPage - 1);
    }

    return (
      <nav aria-label="Pagination">
        <ul className="pagination">
          <li className="pagination-previous">
            <a className={`${currentPage === 1 ? 'disabled' : ''}`} onClick={prevPage} href="#" aria-label="Previous page">
              Previous
            </a>
          </li>

          {pageNumbers.map(pgNumber => (
            <li key={pgNumber}
              className= {currentPage === pgNumber ? 'current' : ''} >
              <a onClick={(evt) => {
                evt.preventDefault();
                setCurrentPage(_this, pgNumber)
              }}
              href='#'
              aria-label={`Page ${pgNumber} `}>
                {pgNumber}
              </a>
            </li>
          ))}
          
          <li className="pagination-next">
            <a className={`${currentPage === nPages ? 'disabled' : ''}`} onClick={nextPage} href='#' aria-label="Next page">
              Next
            </a>
          </li>
        </ul>
      </nav>
    )
}

export default Pagination
