import { DATA_LOADED, SET_USER, ADD_TO_CART, UPDATE_ITEM, REMOVE_ITEM, REMOVE_ALL } from './types'

export const dataLoaded = (isLoaded) => {
  return{
    type: DATA_LOADED,
    isLoaded
  }
}

export const setUser = (user) => {
  return{
    type: SET_USER,
    user
  }
}

export const addToCart = (productId, sku, name, quantity, totalPerCase) => {
  return{
    type: ADD_TO_CART,
    productId,
    sku,
    name,
    quantity,
    totalPerCase
  }
}

export const updateItem = (id, quantity) => {
  return{
    type: UPDATE_ITEM,
    id,
    quantity
  }
}

export const removeItem = (id) => {
  return{
    type: REMOVE_ITEM,
    id
  }
}

export const removeAll = () => {
  return{
    type: REMOVE_ALL
  }
}
