import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
import $ from 'jquery';

const Navigation = () => {
  const { isLoading, isAuthenticated, user, logout } = useAuth0();

  useEffect(() => {
    if (!isLoading && isAuthenticated)  {
      $('.navbar__toggle').on('click', (event) => {
        $(event.currentTarget).toggleClass('navbar__toggle--open');
        $('.navbar__menu-list').toggleClass('navbar__menu-list--open');
      });

      $('.navbar__menu-list-item-link').on('click', (event) => {
        $('.navbar__toggle').toggleClass('navbar__toggle--open');
        $('.navbar__menu-list').toggleClass('navbar__menu-list--open');
      });
    }
  }, [isLoading, isAuthenticated]);

  return (
    isAuthenticated && (
      <nav className="navbar">
        <div className="grid-container">
          <div className="grid-x grid-padding-x">
            <div className="small-12 cell">
              <img className="navbar__logo" src="./assets/img/logo-wht.svg" alt="UNTUCKit" />
              <button className="navbar__toggle">
                <div className="navbar__toggle-icon">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </button>
            </div>
          </div>
        </div>
        <div className={user['https://untuckitsupplies.com/roles'].includes('Administrator') ? 'navbar__menu navbar__menu--admin' : 'navbar__menu'}>
          <div className="grid-container">
            <div className="grid-x grid-padding-x">
              <div className="large-12 cell">
                <ul className="navbar__menu-list">
                  <li className="navbar__menu-list-item"><Link className="navbar__menu-list-item-link" to="/">Home</Link></li>
                  <li className="navbar__menu-list-item" style={{display: user['https://untuckitsupplies.com/roles'].includes('Administrator') ? 'block' : 'none'}}><Link className="navbar__menu-list-item-link" to="/inventory">View Inventory</Link></li>
                  <li className="navbar__menu-list-item"><Link className="navbar__menu-list-item-link" to="/order">New Order</Link></li>
                  <li className="navbar__menu-list-item"><Link className="navbar__menu-list-item-link" to="/cart">View Cart</Link></li>
                  <li className="navbar__menu-list-item"><Link className="navbar__menu-list-item-link" to="/order-history">View Order History</Link></li>
                  <li className="navbar__menu-list-item"><button className="navbar__menu-list-item-link" onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
                    Log Out
                  </button></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
    )
  );
}

export default Navigation;
