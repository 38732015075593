import React, { Component } from 'react';

class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentYear: new Date().getFullYear()
    }
  }

  render() {
    return (
      <footer className="footer">
        <div className="grid-container">
          <div className="grid-x grid-padding-x">
            <div className="large-12 cell center">
              <img className="footer__logo" src="./assets/img/logo-wht.svg" alt="UNTUCKit" />
              <p className="footer__copy">&#169; {this.state.currentYear} UNTUCKit. All rights reserved.</p>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
